import { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux"
import SpartanApi from "../helpers/api";
import {Button, Container, Row, Col, Card, Form} from 'react-bootstrap'
import VehicleCard from "../shared/vehicle-card";
import * as ACTIONS from '../constants/actions';
import {useNavigate} from 'react-router-dom';
import car from '../assets/car.png'
import SpartanButton from '../shared/form-components/spartan-button';
import AddVehicleModal from "../modals/add-vehicle-modal";
import './vehicles.scss';

export default function Vehicles () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const vehicles = useSelector(s => s.vehicles.vehicles);
    const user     = useSelector(s => s.user.data);
    const selectedOrganization = useSelector(s => s.organization.selectedOrganization);
    const organizations = useSelector(s => s.organization.organizations);
    const [modalOpen, setModalOpen] = useState(() => false);
    const [searchTerm, setSearchTerm] = useState(() => '');
    
    const loadVehicles = async () => {
        if (!selectedOrganization  && (!organizations || !organizations.length)) return;
        const id = selectedOrganization ? selectedOrganization.id : organizations[0].id;
        console.log("MADE IT HERE: ", id);
        const hasVehicles = await api.getCustomerOrganizationVehicles({organization_id: id})
        if ((!hasVehicles || !hasVehicles.length)) {
            return;
        }
    }

    const closeModal = async() => {
        console.log("Firing!")
        loadVehicles();
        setModalOpen(() => false)
    }

    useEffect(() => {
        if (!selectedOrganization && (!organizations || !organizations.length)) return;
        console.log("Loading vehicles")
        loadVehicles();
    }, [selectedOrganization, organizations]);

    const buildAddVehicleButton = () => {
        if (!vehicles.length) return null;
        return (
            <div className="text-center mt-5">
                <SpartanButton onClick={() => setModalOpen(true)}> 
                    Add vehicle
                </SpartanButton>
            </div>
        )
    }
    const search = async (evt) => {
        evt.preventDefault();
        if (!searchTerm) return;
        const organization_id = selectedOrganization ? selectedOrganization.id : organizations[0].id;
        await api.searchCustomerOrganizationVehicles({search: searchTerm, organization_id});
    }

    const buildSearchBar = () => {
        return (
            <Col xs="12" className="search-vehicle-container text-center">
                <Form onSubmit={search}>
                    <Form.Group className="mb-3">
                        <Form.Label><h5 className="spartan-heading">Search Vehicles</h5></Form.Label>
                        <Form.Control 
                            placeholder="Search by VIN, Make, Model, or Color"
                            type="text" value={searchTerm} onChange={evt => setSearchTerm(evt.target.value)} />
                    </Form.Group>
                    <SpartanButton type="submit">Search</SpartanButton>
                </Form>
            </Col>
        )
    }
    const buildVehicles = () => {

        if (!vehicles.length){
            return (
                <div className="empty-vehicle-container">
                    <span className="spartan-heading">Hi there,</span><br/>
                    <span className="spartan-heading-bold">{user.first_name}</span>
                    <div className="car-container text-center">
                        <img src={car} />
                    </div>
                    <div className='description-box text-center'>
                        <h5 className='spartan-primary-text'>Add {selectedOrganization ? selectedOrganization.organization_name : organizations[0].organization_name}'s first vehicle</h5>
                        <p className='spartan-secondary-text'>Add your first vehicle to get started! Tap on “Add vehicle” below to start</p>
                    </div>
                    <div>
                        <SpartanButton onClick={() => setModalOpen(true)}> 
                            Add vehicle
                        </SpartanButton>
                    </div>
                </div>
            )
        }

        return (
            <Row>
                {vehicles.map((v, i )=> {
                    return (
                        <Col xs="12"  className="mb-5" key={i}>
                            <VehicleCard key={i} data={v} refreshFunction={() => loadVehicles()} />
                        </Col>
                    )
                })}
            </Row>
        )
    }

    let modal = null;

    if (modalOpen){
        modal = <AddVehicleModal close={closeModal} orgId={selectedOrganization ? selectedOrganization.id : organizations[0].id} />;
    }

   
    if (!organizations.length) return null;
    
    return (
        <div className="main-container">
            {modal}
            <Container>
                <Row>
                    {buildSearchBar()}
                    <h4 className="text-center spartan-heading">Vehicles</h4>
                    {buildVehicles()}
                </Row>
                {buildAddVehicleButton()}
            </Container>
        </div>
    )
}