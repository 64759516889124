import {Modal, Form, Button} from 'react-bootstrap';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import SpartanApi from '../helpers/api';

export default function ForgotPasswordModal({close}) {
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [email, setEmail] = useState(() => '');
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(() => false);

    const triggerPasswordReset = async(evt) => {
        evt.preventDefault();
        const success = await api.attemptForgotPassword({email});
        setSuccessfullySubmitted(success);
        setTimeout(() => close(success), 5000);
    }

    const buildForm = () => {
        if (successfullySubmitted){
            return (
                <div className='p5 text-center'>
                    <h5>
                        If an account with that email address exists, you will receive an email with instructions on how to reset your password.
                    </h5>
                    <p>
                        This window will close in 5 seconds.
                    </p>
                </div>
            )
        }

        return (
            <Form onSubmit={triggerPasswordReset}>
                <Form.Group>
                    <Form.Label>
                        Your Email Address
                    </Form.Label>
                    <Form.Control type="email" value={email} onChange={evt => setEmail(evt.target.value)} required/>
                </Form.Group>
                <div className="text-center">
                    <Button type="submit">RESET PASSWORD</Button>
                </div>
            </Form>
        )
    }

    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header closeButton>
                Forgot Your Password? Trigger a Password Reset below.
            </Modal.Header>
            <Modal.Body>
                {buildForm()}
            </Modal.Body>
        </Modal>
    )
}