import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    alerts: (state = [], action) => {
        switch(action.type){
            case ACTIONS.ADD_ALERT:
                return [...state, action.payload];
            case ACTIONS.REMOVE_ALERT:
                return state.filter((a, i) => i != action.payload);
            case ACTIONS.CLEAR_ALERTS:
                return [];
            default:
                return state;
        }
    }
});