import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";


export default function Alerts(){
    const dispatch = useDispatch();
    const alerts = useSelector(s => s.alert.alerts);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch({type: 'CLEAR_ALERTS'})
        }, 5000);
        return () => clearTimeout(timer);
    })

    const buildAlerts = () => {
        return alerts.map((a, i) => {
            return (
                <Alert key={i} variant={a.variant} onClose={() => dispatch({type: 'REMOVE_ALERT', payload: i})} dismissible>
                    {a.message}
                </Alert>
            )
        })
    }

    return (
        <div className="alert-container">
            {buildAlerts()}
        </div>
    )
}