import {useState, useEffect} from 'react';
import './accept-cookies.scss';
import { Button } from 'react-bootstrap';

export default function AcceptCookies(){
    const [accepted, setAccepted] = useState(() => localStorage.getItem('cookies-accepted'));
    const acceptCookies = () => {
        localStorage.setItem('cookies-accepted', true);
        setAccepted(true);
    }

    useEffect(() => {
        if (accepted) return;
        const cookies = document.getElementById('cookies');
        if (!cookies) return;
        cookies.style.display = 'block';
    }, [accepted]);

    if (accepted) return null;

    return (
        <div id="cookies" className="cookies-container">
            <div className="cookies-text">
                <p>We use cookies solely to enhance your experience on our website by enabling secure login and maintaining session integrity. These cookies are essential for site functionality and do not track or store personal data for analytics or advertising purposes. By continuing to use our website, you agree to our use of these necessary cookies. </p>
                <Button onClick={acceptCookies}>Accept Cookies</Button>
            </div>
        </div>
    )
}