import axios from 'axios';
import * as KEYS from '../constants/keys';
import * as ACTIONS from '../constants/actions';


axios.defaults.baseURL = "https://internal-spartan-tracking-server.westus.cloudapp.azure.com"

export default class SpartanApi {
    constructor({dispatch, navigate}){
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.getToken();
    }

    setToken = (token) => {
        axios.defaults.headers.common.Authorization = token;
        window.localStorage.setItem(KEYS.TOKEN_KEY, token);
    }

    getToken = () => {
        let token = window.localStorage.getItem(KEYS.TOKEN_KEY);
        if (!token) return null;
        axios.defaults.headers.common.Authorization = token;
    }

    setUser = (user) => {
        this.dispatch({type: ACTIONS.SET_USER, payload: user});
        window.localStorage.setItem(KEYS.USER_DATA_KEY, JSON.stringify(user));
    }

    getUser = () => {
        try {
            let storedUser = window.localStorage.getItem(KEYS.USER_DATA_KEY);
            if (!storedUser) return;
            storedUser = JSON.parse(storedUser);
            this.dispatch({type: ACTIONS.SET_USER, payload: storedUser});
            return storedUser;
        } catch(err) {
            console.log(err);
        }
    }

    checkLoggedIn = () => {
        try {
            let user = window.localStorage.getItem(KEYS.USER_DATA_KEY);
            return user;
        } catch(err) {
            
            console.log(err)
            return null
        }
    }

    createUser = async (user) => {
        try {
            const {data} = await axios.post('/customer/create-customer', user);
            this.setToken(data.token);
            this.setUser(data.user);
            return true;
        } catch(err) {
            console.log("Error Creating User: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error creating the user. Please try again.'
                }
            });
            return null;
        }
    }

    login = async (user) => {
        try {
            const {data} = await axios.post('/customer/login', user);
            this.setToken(data.token);
            this.setUser(data.user);
            return true;
        } catch(err) {
            console.log("Error Logging User In; ", err)
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error logging in. Please try again.'
                }
            });
            return null;
        }
    }

    updateUser = async (user) => {
        try {
            await axios.post('/customer/update-customer', user);
            return true;
        } catch(err) {
            console.log("Error Updating the User: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error updating your profile. Please try again.'
                }
            });
            return null;
        }
    }

    createUpdateCustomerAddress = async (address) => {
        try {
            await axios.post('/customer/create-update-customer-address', address);
            return true;
        } catch(err) {
            console.log("Error Creating/Updating Customer Address: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error updating your address. Please try again.'
                }
            });
            return null;
        }
    }

    createCustomerVehicle = async (vehicle) => {
        try {
            await axios.post('/customer/create-customer-vehicle', vehicle);
            return true;
        } catch(err) {
            console.log("Error Creating the Customer Vehicle: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error adding your vehicle to the system. Please try again.'
                }
            });
            return null;
        }
    }

    updateCustomerVehicle = async (vehicle) => {
        try {
            await axios.post('/customer/update-customer-vehicle', vehicle);
            return true;
        } catch(err) {
            console.log("Error Updating the Customer Vehicle: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error updating your vehicle. Please try again.'
                }
            });
            return null;
        }
    }

    getCustomerVehicles = async () => {
        try {
            const {data} = await axios.get('/customer/get-customer-vehicles');
            this.dispatch({type: ACTIONS.SET_USER_VEHICLES, payload: data.vehicles || []});
            return data.vehicles;
        } catch(err) {
            console.log("Error Getting Customer Vehicles", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error getting your vehicles. Please try again.'
                }
            });
            return null;
        }
    }

    createCustomerVehicleDevice = async (device) => {
        try {
            await axios.post('/customer/create-customer-vehicle-device', device);
            return true;
        } catch(err) {
            console.log("Error Creating Customer Vehicle Device: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error adding your device to the vehicle. Please try again.'
                }
            });
            return null;
        }
    }

    createUpdateCustomerDeviceWarranty = async (warranty) => {
        try {
            await axios.post('/customer/create-update-customer-device-warranty', warranty);
            return true;
        } catch(err) {
            console.log("Error Creating/Updating the Device Warranty: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error updating the device warranty. Please try again.'
                }
            });
            return null;
        }
    }

    createCustomerOrganization = async (requestBody) => {
        try {
            await axios.post('/customer/create-customer-organization', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error creating the organization. Please try again.'
                }
            });
            return null;
        }
    }

    getCustomerOrganizationsWithFullData = async () => {
        try {
            const {data} = await axios.get('/customer/get-user-organizations-and-their-users');
            this.dispatch({type: ACTIONS.SET_ORGANIZATIONS_WITH_FULL_DATA, payload: data.organizations})
            return data.organizations;
        } catch(err) {
            console.log(err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error getting your organizations. Please try again.'
                }
            })
            return null;    
        }
    }

    getCustomerOrganizations = async () => {
        try {
            const {data} = await axios.get('/customer/get-customer-organizations')
            this.dispatch({type: ACTIONS.SET_ORGANIZATION_OPTIONS, payload: data.organizations});
            return true;
        } catch(err){
            console.log("ERROR: ", err);
            
            return null;
        }
    }

    getCustomerOrganizationVehicles = async (requestBody) => {
        try {
            const {data} = await axios.get('/customer/get-customer-organization-vehicles', {params:requestBody})
            this.dispatch({type: ACTIONS.SET_USER_VEHICLES, payload: data.vehicles || []});
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    searchCustomerOrganizationVehicles = async (requestBody) => {
        try {
            const {data} = await axios.get('/customer/search-customer-organization-vehicles', {params: requestBody});
            this.dispatch({type: ACTIONS.SET_USER_VEHICLES, payload: data.vehicles || []});
        } catch(err) {
            console.log("ERROR: ", err);
            return
        }
    }

    createCustomerOrganizationVehicle = async (requestBody) => {
        try {
            await axios.post('/customer/create-customer-organization-vehicle', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null
        }
    }

    updateCustomerOrganizationVehicle = async (requestBody) => {
        try {
            await axios.post('/customer/update-customer-organization-vehicle', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    resetPassword = async (requestBody) => {
        try {
            await axios.post('/customer/reset-password', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error resetting your password. Please try again.'
                }
            });
            return null;
        }
    }

    attemptForgotPassword = async(requestObj) => {
        try {
            await axios.post('/customer/forgot-password', requestObj);
            return true;
        } catch(err) {
            console.log(err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'There was an error when we tried to send you a password reset link.'
                }
            });
            return null;
        }
    }

    removeOrganizationUser = async(requestObj) => {
        try {
            await axios.post('/customer/remove-customer-organization-user', requestObj);
            return true;
        } catch(err) {
            console.log(err);
            this.dispatch({
                type: ACTIONS.ADD_ALERT,
                payload: {
                    variant: 'danger',
                    message: 'Unable to remove the user from the organization. Please try again.'
                }
            });
            return null;
        }
    }

    attemptAddOrganizationUser = async(requestObj) => {
        try {
            await axios.post('/customer/add-customer-organization-user', requestObj);
            return true;
        } catch(err) {
            console.log(err)
            this.alertHelper.addAlert({
                title: 'Unable to add user to your Organization',
                description: 'Please try again later and contact support if the issue persists.'
            });
            return null;
        }
    }

    attemptUpdateOrganizationUser = async(requestObj) => {
        try {
            await axios.post('/customer/update-user-in-customer-organization', requestObj);
            return true;
        } catch(err) {
            console.log(err);
            this.alertHelper.addAlert({
                title: 'Unable to update the user access status for this Organization',
                description: 'Please try again later and contact support if the issue persists.'
            });
            return null;
        }
    }

}