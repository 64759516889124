import {Modal, } from 'react-bootstrap';


export default function EulaModal({close, }){


    return (
        <Modal show={true} onHide={close} style={{backgroundColor: 'rgba(0,0,0,0.9)'}} size="xl">
            <Modal.Header className="spartan-modal" closeButton>
                <h6 className="text-center">
                END USER LICENSE AGREEMENT
                </h6>
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <embed src="./terms.pdf" width="100%" height={window.screen.height - 50}/>
            </Modal.Body>
        </Modal>
    )

    
}