import {useState} from 'react';
import {useDispatch} from 'react-redux';
import SpartanApi from '../helpers/api';
import { useNavigate } from 'react-router-dom';
import { Modal, Card, Form, Button } from 'react-bootstrap';

export default function AddOrganizationUserModal({close, organization}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));

    const [newUser, setNewUser] = useState(() => {
        return {
            first_name: '',
            last_name: '',
            email: '',
            user_password: ''
        }
    });

    const onSave = async (evt) => {
        evt.preventDefault();
        await api.attemptAddOrganizationUser({...newUser, organization_id: organization.id});
        close();
    }
    
    const handleChange = (key, value) => {
        setNewUser({
            ...newUser,
            [key]: value
        })
    }

    if (!organization) return null;

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header closeButton>
                Add User to {organization.organization_name}
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={onSave}>
                    <Form.Group>
                        <Form.Label>User's First Name</Form.Label>
                        <Form.Control type="text" value={newUser.first_name} onChange={evt => handleChange('first_name', evt.target.value)} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>User's Last Name</Form.Label>
                        <Form.Control type="text" value={newUser.last_name} onChange={evt => handleChange('last_name', evt.target.value)} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>User's Email Address</Form.Label>
                        <Form.Control type="email" value={newUser.email} onChange={evt => handleChange('email', evt.target.value)} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>User's Initial Password (Will be emailed to them)</Form.Label>
                        <Form.Control type="password" value={newUser.user_password} onChange={evt => handleChange('user_password', evt.target.value)} required/>
                    </Form.Group>
                    <div className='mt-3 text-center'>
                        <Button type="submit">ADD USER</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}