import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    selectedOrganization: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SELECT_ORGANIZATION: {
                return action.payload;
            }
            default: return state;
        }
    },
    organizations: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_ORGANIZATION_OPTIONS: {
                return action.payload;
            }
            default: return state;
        }
    },
    organizationsWithFullData: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_ORGANIZATIONS_WITH_FULL_DATA: {
                return action.payload;
            }
            default: return state;
        }
    }
})