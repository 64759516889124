import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import SpartanApi from '../helpers/api';
import {Modal, Form, Button} from 'react-bootstrap';
const options = ['admin', 'user'];


export default function UpdateOrganizationUser({organization, user, close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [accessLevel, setAccessLevel] = useState(() => user.access_level || 'user');

    const onSave = async (evt) => {
        evt.preventDefault();
        const requestObj = {
            access_level: accessLevel,
            organization_id: organization.id,
            customer_id: user.customer_id
        }

        await api.attemptUpdateOrganizationUser(requestObj);
        close();
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header closeButton>
                Update {user.first_name} {user.last_name}'s Access Level
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={onSave}>
                    <Form.Group>
                        <Form.Label>User Access Level</Form.Label>
                        <Form.Select value={accessLevel} onChange={evt => setAccessLevel(evt.target.value)} required>
                            {options.map((o, i) => {
                                return (
                                    <option value={o} key={i}>{o}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <div className="mt-3 text-center">
                        <Button type="submit">UPDATE ACCESS LEVEL</Button>
                    </div>
                </Form>
            </Modal.Body>

        </Modal>
    )
}