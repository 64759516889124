import {useSelector, useDispatch} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import SpartanApi from '../helpers/api';
import CreateOrganizationModal from '../modals/create-organization-modal';
import { Button, Card, Table } from 'react-bootstrap';
import UpdateOrganizationUser from '../modals/update-user-modal';
import AddOrganizationUserModal from '../modals/add-user-modal';
import moment from 'moment';

export default function ViewOrganizations(){
    const dispatch = useDispatch();
    const api = useMemo(() => new SpartanApi({dispatch}), []);
    const organizations = useSelector(state => state.organization.organizationsWithFullData);
    const user = useSelector(state => state.user.data);
    const [isLoading, setIsLoading] = useState(true);
    const [addUserModalOpen, setAddUserModalOpen] = useState(() => false);
    const [updateUserModalOpen, setUpdateUserModalOpen] = useState( () => false);
    const [modalOrg, setModalOrg] = useState(() => null);
    const [modalUser, setModalUser] = useState(() => null);
    const [createOrgModalOpen, setCreateOrgModalOpen] = useState(() => false);

    const closeModal = async () => {
        console.log("Clicking")
        await loadData();
        setCreateOrgModalOpen(false);
        setAddUserModalOpen(false);
        setUpdateUserModalOpen(false);
        setModalOrg(null);
        setModalUser(null);
        console.log("CLOSED");
    }
    const loadData = async () => {
        await api.getCustomerOrganizationsWithFullData();
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    const buildOrganizations = () => {
        if (!organizations.length) return null;
        return organizations.map((org, i) => {
            const info  = org.organization_information;
            const users = org.organization_users;
            const activeUserCount = users.filter(u => u.is_active).length;
            let addUserButton = null;
            if (org.access_level && org.access_level.toLowerCase() == 'admin'){
                addUserButton = (
                    <Button onClick={() =>{
                        setModalOrg(info);
                        setAddUserModalOpen(true);
                    }}>
                        ADD NEW USER
                    </Button>
                )
            } 
            return (
                <div key={i} className="mt-5">
                    <h4>{info.organization_name}</h4>
                    <h6>Created On {moment(info.created_on).format('MM/DD/YYYY hh:mma')}</h6>
                    <h6>{addUserButton}</h6>

                    <div className="mt-3">
                        <h5>Users ({activeUserCount})</h5>
                        <Table variant="dark" striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Access Level</th>
                                    {org.access_level && org.access_level.toLowerCase() == 'admin' && (
                                        <th>
                                            Update / Remove
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((u, i) => {
                                   
                                    if (!u.is_active) return null;
                                    const isSameUser = u.customer_id == user.id; //Don't allow a user to do something dumb
                                    return (
                                        <tr key={i}>
                                            <td>{u.first_name} {u.last_name}</td>
                                            <td>{u.email}</td>
                                            <td>{u.access_level}</td>
                                            {org.access_level && org.access_level.toLowerCase() == 'admin' && (
                                                <td>
                                                    <Button disabled={isSameUser} onClick={() => {
                                                        setModalOrg(info);
                                                        setModalUser(u);
                                                        setUpdateUserModalOpen(true);
                                                    }}>
                                                        Update
                                                    </Button>
                                                    <Button variant="danger" disabled={isSameUser} onClick={() => removeUserFromOrganization(org.organization_id, u.customer_id)}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )
        })
    }

    const removeUserFromOrganization = async (organizationId, customerId) => {
        const removalConfirmed = window.confirm("Are you sure you want to remove this user from the organization?");
        if (!removalConfirmed) return;
        await api.removeOrganizationUser({organization_id: organizationId, customer_id: customerId});
        await loadData();
    }

    let modal = null;

    if (createOrgModalOpen){
        modal = <CreateOrganizationModal close={closeModal} />
    } else if (addUserModalOpen && modalOrg){
        modal = <AddOrganizationUserModal organization={modalOrg} close={closeModal} />
    } else if (updateUserModalOpen && modalOrg && modalUser){
        modal = <UpdateOrganizationUser user={modalUser} organization={modalOrg} close={closeModal} />
    }

    if (isLoading){
        return (
            <div className="main-container">
                <div className="p5 text-center">
                    Loading...
                </div>
            </div>
        )
    }

    if (!organizations.length){
        return (
            <div className="main-container">
                {modal}
                <div className="p5 text-center">
                    No organizations found. <a href="#" onClick={() => setCreateOrgModalOpen(true)}>Create One Here</a>
                </div>
            </div>
        )
    }

    return (
        <div className="main-container">
            {modal}
            <Card bg="dark" text='white'>
                <Card.Header className="text-center"><h5>Your Organizations</h5></Card.Header>
                <Card.Body>
                    <div className="mb-5">
                        <Button variant="success" onClick={() => setCreateOrgModalOpen(true)}>
                            Create New Organization
                        </Button>
                    </div>
                    {buildOrganizations()}
                </Card.Body>
            </Card>
        </div>
    )
}