import {Modal, Button, Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import SpartanApi from '../helpers/api';
import { QrReader } from 'react-qr-reader';

let placementOptions = ["Front", "Rear", "Passenger Side", "Driver's Side"]
let hmiPlacementOptions = ["Cab - Driver's Side", "Cab - Passenger Side", "Cab - Center"];
export default function AddDeviceModal({close, customer_vehicle_id}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [device, setDevice] = useState(() => {
        return {
            customer_vehicle_id,
            serial_number: undefined,
            placement: "Rear"
        }
    });
    const [manualSerialNumber, setManualSerialNumber] = useState(() => '');

    const selectedOrganization = useSelector(s => s.organization.selectedOrganization);

    const decipher = (result, error) => {
        if (error) return;
        setDevice({...device, serial_number: result.text})
    }

    const saveDeviceToVehicle = async(evt) => {
        evt.preventDefault();
        await api.createCustomerVehicleDevice({
            customer_vehicle_id,
            serial_number: device.serial_number,
            placement: device.placement,
            organization_id: selectedOrganization.id
        })
        
        close();
    }

    const manualSerialNumberSubmit = (evt) => {
        evt.preventDefault();
        if (!manualSerialNumber || manualSerialNumber.length < 10 || (manualSerialNumber.toUpperCase().indexOf('HLCD') <= -1) && manualSerialNumber.toUpperCase().indexOf('HSENIXX') <= -1){
            return;
        }
        setDevice({...device, serial_number: manualSerialNumber.toUpperCase()})
        setManualSerialNumber('')
    }

    let section = null;

    if (!device.serial_number){
        section = (
        <div>
            <h5>
                Please Scan the QR Code Associated with the Device
            </h5>
            <div style={{width: 300, height: 300, margin: '0px auto'}}>
                <QrReader  constraints={{facingMode: 'environment'}} onResult={decipher} style={{ width: '100%' }} />
            </div>
            <hr/>
            <div>
                <h5 className="mt-4 text-center">
                    Having trouble scanning? Manually enter the serial number below.
                </h5>
                <br/>
                <Form onSubmit={manualSerialNumberSubmit}>
                    <Form.Group>
                        <Form.Label>
                            Serial Number
                        </Form.Label>
                        <Form.Control
                            required
                            value={manualSerialNumber}
                            onChange={evt => setManualSerialNumber(evt.target.value)}
                            placeholder="HSENIXX,XXXX-XXXXX"
                        />
                    </Form.Group>
                    <div className="text-center mt-3">
                        <Button type="submit">SUBMIT</Button>
                    </div>
                </Form>
            </div>
        </div>
        )
    }

    if (device.serial_number) {
       section =  <div>
            <Form onSubmit={saveDeviceToVehicle}>
                <Form.Group>
                    <Form.Label>
                        Serial Number: {device.serial_number}
                    </Form.Label>
                </Form.Group>
                <Form.Group className="mt-4">
                    <Form.Label>
                        Where will this device be placed on the vehicle?
                    </Form.Label>
                    {
                        device.serial_number.indexOf('HLCD') > -1 && <Form.Select value={device.placement} onChange={evt => {setDevice({...device, placement: evt.target.value})}}>
                            {hmiPlacementOptions.map((p, i) => {
                                return <option key={i} value={p}>{p}</option>
                            })}
                        </Form.Select>
                    }
                    {
                        device.serial_number.indexOf('HSENIXX') > -1 && <Form.Select value={device.placement} onChange={evt => {setDevice({...device, placement: evt.target.value})}}>
                            {placementOptions.map((p, i) => {
                                return <option key={i} value={p}>{p}</option>
                            })}
                        </Form.Select>
                    }
                    
                </Form.Group>
                <div className="mt-4 text-center">
                    <Button type="submit">ADD DEVICE TO VEHICLE</Button>
                </div>
            </Form>
        </div>
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal">
                ADD DEVICE TO VEHICLE
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <div>
                    {section}
                </div>
            </Modal.Body>
        </Modal>
    )
}