import './App.css';
import { Col, Row, Container, Form, Button, Modal, Card } from 'react-bootstrap';
import {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SpartanApi from './helpers/api';
import { useNavigate } from 'react-router-dom';
import LoginModal from './modals/login-modal';
import ParticleImage from 'react-particle-image';
import SpartanTextInput from './shared/form-components/spartan-text-input';
import SpartanButton from './shared/form-components/spartan-button';
import EulaModal from './modals/eula-modal';
import ForgotPasswordModal from './modals/forgot-password-modal';

const particleOptions = {
  filter: ({ x, y, image }) => {
    // Get pixel
    const pixel = image.get(x, y);
    // Make a particle for this pixel if blue > 50 (range 0-255)
    return pixel.b > 0;
  },
  color: ({ x, y, image }) => "#61dafb",
  size: 0.1
};
function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.user.data);
  const [api] = useState(() => new SpartanApi({dispatch}))
  const [modalOpen, setModalOpen] = useState(() => false);
  const [formState, setFormState] = useState(() => 'registration');
  const [eulaModalOpen, setEulaModalOpen] = useState(() => false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(() => false);
  const [user, setUser] = useState(() => {
    return {
      email: '',
      user_password: '',
      first_name: '',
      last_name: '',
      accept_terms: false
    }
  })

  const [loginUser, setLoginUser] = useState(() => {
    return {
        email: '',
        user_password: ''
    }
});

const attemptLogin = async (evt) => {
    evt.preventDefault();
    const loggedIn = await api.login(loginUser);

    if (!loggedIn){
        return;
    }

   // navigate("/add-vehicle")
   window.location.href = "/organization"
}
  const [containerWidth, setContainerWidth] = useState(() => 400);
  const parentContainerRef = useRef(null);

  const attemptCreateUser = async (evt) => {
    evt.preventDefault();
   const created = await api.createUser(user);

   if (!created){
    //TODO: ADD AN ALERT;
    console.log("ADD ALERT")
    return;
   } 

   window.location.href = "/edit-address"
  }

  const onClose = async () => {
    setModalOpen(false);
  }

  useEffect(() => {
    const handleResize = () => {
      if (parentContainerRef.current) {
        const containerWidth = parentContainerRef.current.offsetWidth;
        setContainerWidth(containerWidth);
        // Perform any additional actions based on the new width
      }
    };

    // Attach event listener to window resize
    window.addEventListener('resize', handleResize);
    handleResize();
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!loggedInUser) return;
    navigate("/organization")
  }, [loggedInUser])

  const buildLogin = () => {
    return (
      <div style={{margin: "0px auto"}}>
        <h4 className="frontpage-heading">Login to access your account</h4>
        <Form onSubmit={attemptLogin}>
          <Form.Group>
            <SpartanTextInput type="email" label="Email" value={loginUser.email} onChange={evt => setLoginUser({...loginUser, email: evt.target.value})} required={true}/>
          </Form.Group>

          <Form.Group className='mt-2'>
            <SpartanTextInput type="password" label="Password" value={loginUser.user_password} onChange={evt => setLoginUser({...loginUser, user_password: evt.target.value})} required={true}/>
          </Form.Group>

          <div className="mt-4 text-center">
            <SpartanButton type="submit">
              Login
            </SpartanButton>
          </div>

          <hr className="mt-5"/>
          <div className="text-center">
              <a className="spartan-link" onClick={() => setForgotPasswordModalOpen(true)}>Forgot your password?</a>
          </div>
          <br/>
          <div className="text-center">
              <a className="spartan-link" onClick={() => setFormState("registration")}>Don't have an account? Create an account</a>
          </div>
          
        </Form>
      </div>
    )
  }

  const buildRegistration = () => {
    return (
      <div style={{margin: "0px auto"}}>
      <h4 className="frontpage-heading">Register <span className="highlighted">your devices</span> and assign them to your vehicles</h4>
      <Form onSubmit={attemptCreateUser} className="mt-5">

          <Form.Group>
             <SpartanTextInput label="Your Email" value={user.email}  onChange={evt => setUser({...user, email: evt.target.value})} required={true} />
          </Form.Group>

          <Form.Group className="mt-2">
            <SpartanTextInput label="First Name" type="text" value={user.first_name} onChange={evt => setUser({...user, first_name: evt.target.value})} required/>
            
          </Form.Group>

          <Form.Group className="mt-2">
            <SpartanTextInput label="Last Name" type="text" value={user.last_name} onChange={evt => setUser({...user, last_name: evt.target.value})} required/>
          </Form.Group>

          <Form.Group className="mt-2">
            <SpartanTextInput type="password" label="Your Password" value={user.user_password} onChange={evt => setUser({...user, user_password: evt.target.value})} required={true} />
          </Form.Group>

          <Form.Group className="spartan-form-group-container">
            <Form.Check checked={user.accept_terms} type="checkbox" label={(
              
                <div className="label">
                  I accept the <a href="#"onClick={() => setEulaModalOpen(true)}>terms and conditions</a>
                </div>
              
            )} required onChange={(evt) => setUser({...user, accept_terms: evt.target.checked})}/>
          </Form.Group>

          <div className="mt-4 text-center">
            <SpartanButton type="submit">
              Continue
            </SpartanButton>
          </div>

          <div className="mt-4 text-center"> 
              <a className="spartan-link" onClick={() => setFormState('login')}>Have an account? Login</a>
          </div>
      </Form>
      </div>
    )
  }

  let modal = null;

  if (modalOpen) {
      modal = <LoginModal close={onClose}/>
  }

  if (eulaModalOpen) {
      modal = <EulaModal close={() => setEulaModalOpen(false)}/>
  }

  if (forgotPasswordModalOpen) {
      modal = <ForgotPasswordModal close={() => setForgotPasswordModalOpen(false)}/>
  }

  let section = null;

  if (formState == "registration") {
    section = buildRegistration();
  } else {
    section = buildLogin();
  }

  return (
    <div className="main-container">
      {modal}
    <Container>
      <Row>
        <Col xs={12} sm={6} md={6} lg={4} className="mt-4"> 
             {section}
        </Col>

        <Col xs={12} sm={6} md={6} lg={8} className="d-none d-sm-block mt-4">
          <div ref={parentContainerRef}>
          <ParticleImage
              style={{backgroundColor: 'rgba(0,0,0,0)'}}
              src={"/car.png"}
              scale={1}
              entropy={10}
              maxParticles={5000}
              particleOptions={particleOptions}
              width={containerWidth}
          />
          </div>
        </Col>

      </Row>
    </Container>
    </div>
  );
}

export default App;
